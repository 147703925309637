@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black: #000000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  background-color: #f4f5fa;
  overflow-y: hidden;
  overflow-x: visible;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: visible;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding: 10rem;
}

#root {
  width: 100%;
  height: 100%;
}

.spin-full-width.ant-spin-nested-loading {
  width: 100%;
  height: 100%;
}

.spin-full-width.ant-spin-nested-loading .ant-spin-container {
  width: 100%;
  height: 100%;
}

.spin-full-width.ant-spin-nested-loading .ant-spin.ant-spin-spinning {
  width: 100%;
  height: 100%;
}

.ant-modal .ant-modal-content {
  min-width: 250px;
  width: 100%;
} 

.ant-select .ant-select-selector {
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}